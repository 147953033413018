<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-card>
      <v-card-text
        class="mt-10"
        style="background:white; min-height:700px"
      >
        <v-row class="d-flex justify-center">
          <v-col
            cols="4"
            class="ma-5 d-flex"
            style="background:#B6EDFF; border-radius:30px"
          >
            <v-col
              cols="6"
              class="pa-0"
            >
              <span class="mr-5 blue--text">{{ $t('messages.managerIdCustomer') }}</span>
              {{ customerDetail.code }}
            </v-col>
            <v-col
              cols="6"
              class="pa-0"
            >
              <span class="mr-5 blue--text">{{ $t('messages.factoryName') }}</span>
              {{ name }}
            </v-col>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row class="d-flex justify-center align-center">
              <v-col cols="2">
                <span>{{ $t('messages.applicablePeriod') }}</span>
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="menuStart"
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :return-value.sync="startAt"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startAt"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="startAt"
                    locale="ja"
                    no-title
                    scrollable
                    :max="getMaxDate"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menuStart = false"
                    >
                      {{ $t('messages.cancel') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuStart.save(startAt)"
                    >
                      {{ $t('messages.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                cols="1"
                class="d-flex justify-center"
              >
                ~
              </v-col>
              <v-col cols="3">
                <v-menu
                  ref="menuEnd"
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  :return-value.sync="endAt"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endAt"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="endAt"
                    locale="ja"
                    no-title
                    scrollable
                    :min="getMinDate"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menuEnd = false"
                    >
                      {{ $t('messages.cancel') }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menuEnd.save(endAt)"
                    >
                      {{ $t('messages.ok') }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row class="d-flex justify-center align-center">
              <v-col cols="2">
                <span>{{ $t('messages.applicableMethod') }}</span>
              </v-col>
              <v-col
                cols="4"
                class="d-flex align-center"
              >
                <v-select
                  v-model="type"
                  outlined
                  :items="methodsList"
                  item-text="text"
                  item-value="value"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col
            cols="8"
            class="pa-7"
            style="border:1px solid #5CA6D2 ;border-radius:10px;"
          >
            <v-row
              v-if="type === 1"
              class="d-flex justify-center"
            >
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-1"
                :loading-text="$t('table.messages.loading')"
                :loading="loading"
                :footer-props="{
                  itemsPerPageOptions: [5, 10, 15, 30, 50],
                  showFirstLastPage: false,
                  'items-per-page-text': $t('table.messages.items_per_page'),
                }"
              >
                <template v-slot:item.isUsed="{ item }">
                  <v-checkbox
                    v-model="item.isUsed"
                    hide-details
                  />
                </template>
                <template v-slot:item.index="{ index }">
                  {{ index + 1 }}
                </template>
                <template v-slot:item.manageByShift="{ item }">
                  <v-row class="d-flex justify-center">
                    <v-checkbox
                      v-model="item.manageByShift"
                      class="d-flex justify-center"
                      hide-details
                    />
                  </v-row>
                </template>
                <template v-slot:item.shift="{ item }">
                  <template v-if="item.manageByShift === true">
                    <v-row
                      v-for="(shift, index) in item.shifts"
                      :key="index"
                      class="d-flex mb-1 mt-1"
                      style="background: rgb(182, 237, 255);border-radius: 20px;"
                    >
                      <v-col class="d-flex justify-center">
                        <span>{{ shift.shiftStartAt + ' ~ ' + shift.shiftEndAt }}</span>
                      </v-col>
                    </v-row>
                  </template>
                </template>
                <template v-slot:item.hourlyRevenue="{ item }">
                  <template v-if="item.manageByShift === true">
                    <v-row
                      v-for="(shift, index) in item.shifts"
                      :key="index"
                    >
                      <v-col>
                        <v-text-field
                          v-model="shift.hourlyRevenue"
                          type="number"
                          :rules="hourlyRules"
                          min="0"
                          :suffix="$t('suffix.money')"
                          hide-details="auto"
                        />
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    <v-text-field
                      v-model="item.hourlyRevenue"
                      type="number"
                      :rules="hourlyRules"
                      min="0"
                      :suffix="$t('suffix.money')"
                      hide-details="auto"
                    />
                  </template>
                </template>
                <template v-slot:item.note="{ item }">
                  <v-text-field
                    v-model="item.note"
                    hide-details="auto"
                  />
                </template>
                <template slot="no-data">
                  {{ $t('table.messages.no_data') }}
                </template>
                <template
                  v-slot:footer.page-text
                  class="mr-0"
                >
                  {{ $t('table.messages.page') }}
                  {{ options.page }}
                </template>
                <template v-slot:footer.prepend />
              </v-data-table>
            </v-row>
            <v-row
              v-else
              class="d-flex justify-center align-start"
            >
              <v-col
                cols="7"
                class="d-flex align-start"
              >
                <span class="blue--text">{{ $t('messages.contract') }}</span>
                <v-text-field
                  v-model="hourlyRevenue"
                  class="mt-n4 ml-5 shrink"
                  solo
                  style="width:100px"
                  type="number"
                  :rules="hourlyRules"
                  min="0"
                  :suffix="$t('suffix.money')"
                />
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center ml-n15 mr-15"
              >
                <span class="blue--text">{{ $t('messages.note') }}</span>
                <v-text-field
                  v-model="note"
                  class="mt-n4 ml-5 shrink"
                  solo
                  style="width:40%"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            large
            @click="save"
          >
            {{ $t('button.save') }}
          </v-btn>
          <v-btn
            color="dark darken-1"
            large
            @click="redirectDetail"
          >
            {{ $t('button.cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import moment from 'moment'

  export default {
    name: 'RevenueEdit',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        startAt: '',
        endAt: '',
        name: '',
        menuStart: false,
        menuEnd: false,
        options: {},
        headers: [
          {
            text: '',
            align: 'center',
            value: 'isUsed',
            class: 'font-weight-bold',
            width: '3em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.index'),
            align: 'center',
            value: 'index',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.job'),
            align: 'center',
            value: 'job',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.manageByShift'),
            align: 'center',
            value: 'manageByShift',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.shift'),
            align: 'center',
            value: 'shift',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.hourlyRevenue'),
            align: 'center',
            value: 'hourlyRevenue',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: this.$t('table.headers.remarks'),
            align: 'center',
            value: 'note',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
        ],
        methodsList: [
          {
            text: this.$t('combobox.manage_by_factory'),
            value: 0,
          },
          {
            text: this.$t('combobox.manage_by_job'),
            value: 1,
          },
        ],
        type: 1,
        items: [],
        loading: false,
        totalItems: 1,
        hourlyRevenue: 0,
        note: '',
        hourlyRules: [
          v => !!v || 'この項目は必須です',
          v => (v && v >= 0) || '値は0より大きくなければなりません',
        ],
      }
    },
    computed: {
      ...get('customer', ['customerDetail']),
      ...get('revenue', ['revenueDetail', 'listJobs', 'revenueInfo']),
      getMinDate () {
        const startAt = this.startAt
        return startAt
      },
      getMaxDate () {
        const endAt = this.endAt
        return endAt
      },
    },
    watch: {
      status (value) {
        this.loading = value === 'loading'
      },
      error (value) {
        this.showSnackBar(value)
      },
      message (value) {
        if (value === 'success') {
          this.showSnackBar('messages.success')
          this.$router.push('/revenue-management/' + this.$route.params.id)
        }
      },
      customerDetail (value) {
        this.name = value.user.name
      },
      revenueDetail (value) {
        this.startAt = moment(value[0].startAt).format('YYYY-MM-DD')
        this.endAt = moment(value[0].endAt).format('YYYY-MM-DD')
        this.type = value[0].type
        if (this.type === 0) {
          this.hourlyRevenue = value[0].hourlyRevenue
          this.note = value[0].note
        }
      },
      listJobs (value) {
        value.map(item => {
          this.items.push({
            id: item.id,
            job: item.name,
            manageByShift: item.manageByShift,
            hourlyRevenue: item.hourlyRevenue,
            note: item.note,
            isUsed: item.isUsed,
            shifts: item.shifts,
          })
        })
      },
      revenueInfo (value) {
        const payload = {
          customerId: this.$route.params.id,
          startAt: value.startAt,
          endAt: value.endAt,
          type: value.type,
        }
        localStorage.setItem('dataSearch', JSON.stringify(payload))
        this.$router.push('/revenue-management/' + this.$route.params.id)
      },
    },
    created () {
      var dataSearch = JSON.parse(localStorage.getItem('dataSearch'))
      this.$store.dispatch('customer/getCustomer', {
        id: this.$route.params.id,
        includeAll: 1,
        forRevenue: 1,
      })
      this.$store.dispatch('revenue/getDetailRevenue', dataSearch)
    },
    mounted () {},
    methods: {
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      save () {
        let payload = {}
        if (this.type === 0) {
          payload = {
            customerId: this.$route.params.id,
            revenues: this.revenueDetail,
            listJobs: this.items,
            type: this.type,
            startAt: this.startAt,
            endAt: this.endAt,
            hourlyRevenue: this.hourlyRevenue,
            note: this.note,
          }
        } else {
          this.items.map(item => {
            if (item.manageByShift === true) {
              delete item.hourlyRevenue
            } else {
              item.shifts.map(shift => {
                delete shift.hourlyRevenue
              })
            }
          })
          payload = {
            customerId: this.$route.params.id,
            revenues: this.revenueDetail,
            listJobs: this.items,
            type: this.type,
            startAt: this.startAt,
            endAt: this.endAt,
          }
        }
        this.$store.dispatch('revenue/update', payload)
      },
      redirectDetail () {
        this.$router.push('/revenue-management/' + this.$route.params.id)
      },
    },
  }
</script>
